import React, { useState, createContext } from "react"

const defaults = {
  search: "",
  setSearch: search => {},
}

const SearchContext = createContext(defaults)

const SearchProvider = ({ children }) => {
  const [search, setSearch] = useState(defaults.search)

  return (
    <SearchContext.Provider
      value={{
        search,
        setSearch,
      }}
    >
      {children}
    </SearchContext.Provider>
  )
}

export { SearchContext, SearchProvider }
